import { useEffect, useState } from 'react';
import { CometChatCalls } from '@cometchat/calls-sdk-javascript';
import Button from 'anchor-ui/button';
import { IconClose } from 'anchor-ui/icons';
import { Call, CometChat } from "@cometchat/chat-sdk-javascript";

const OnGoingCallContainer = (
  {
    ongoingCall,
    setOngoingCall,
    outgoingCall,
    setOutgoingCall,
  }: {
    ongoingCall: Call | null;
    setOngoingCall: (ongoingCall: Call | null) => void;
    outgoingCall: { call: Call, receiver: CometChat.User } | null;
    setOutgoingCall: (outgoingCall: { call: Call, receiver: CometChat.User } | null) => void;
  }) => {

  if (!ongoingCall && !outgoingCall) return null;

  const [timePassed, setTimePassed] = useState('00:00');

  useEffect(() => {
    if (!ongoingCall && !outgoingCall) {
      setTimePassed('00:00');
      return;
    };

    // Hardcoded start time
    const startTime = (ongoingCall?.getInitiatedAt() ?? outgoingCall?.call.getInitiatedAt() ?? new Date().getTime()) * 1000;

    const calculateTimePassed = () => {
      const now = new Date().getTime();
      const diff = now - startTime;

      const hours = Math.floor(diff / 3600000);
      const minutes = Math.floor((diff % 3600000) / 60000);
      const seconds = Math.floor((diff % 60000) / 1000);

      const formattedHours = hours < 10 ? `0${hours}` : hours;
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
      const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

      if (hours > 0) {
        setTimePassed(`${formattedHours}:${formattedMinutes}:${formattedSeconds}`);
      } else {
        setTimePassed(`${formattedMinutes}:${formattedSeconds}`);
      }
    };

    const interval = setInterval(calculateTimePassed, 1000);

    return () => clearInterval(interval);
  }, [ongoingCall, outgoingCall]);

  const endCall = async () => {
    // End the call
    try {
      if (outgoingCall) await CometChat.rejectCall(outgoingCall?.call.getSessionId() + "", CometChat.CALL_STATUS.CANCELLED);

      CometChatCalls.endSession();
      setOngoingCall(null);
      setOutgoingCall(null);
    } catch (e) {
      console.error("Error while terminating call:", e);
    }
  };

  return (
    <div className="ongoing-call-container">
      <div className="ongoing-call-info">
        <div>
          {outgoingCall ? <p>{outgoingCall?.receiver?.getName() ?? "404"} bellen...</p> :
            ongoingCall && <p>Audio gesprek met {ongoingCall.getReceiver().getName()}</p>
          }
          <p>{timePassed}</p>
        </div>

        <Button iconButton onClick={endCall} >
          <IconClose color="white" />
        </Button>
      </div>
    </div>
  );
};

export default OnGoingCallContainer;