import { Call, CometChat } from "@cometchat/chat-sdk-javascript";
import List from 'anchor-ui/list';
import ListItem from 'anchor-ui/list-item';

import { ActiveChannelInterface } from './../../interfaces/activeChannelInterface';
import AddUserToGroupModal from './AddUserToGroupModal';
import { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import { checkIfUserIsBlocked } from '../../utils/checkIfUserIsBlocked';
import { getProfileFromMetadata } from '../../utils/updateMetadata';
import { Functions } from 'firebase/functions';
import GroupMemberListItem from './GroupMemberListItem';
import { getFontSizeStyle } from '../../utils/getFontSizeStyle';
import { UserSettingsInterface } from '../../interfaces/userSettingsInterface';
import {
  checkIfUserHasPermissions,
  checkIfUserIsAdmin,
  checkIfUserIsModerator
} from '../../utils/checkIfUserIsAdmin';
import {
  IconSettings,
  IconPeople,
  IconSticker,
} from 'anchor-ui/icons';
import IconMenu from 'anchor-ui/icon-menu';
import MenuItem from 'anchor-ui/menu-item';
import { premiumChannelIds } from "../../utils/premiumChannelIds";
import { checkUserHasSubscription } from "../../utils/checkSubscription";

interface MemberCategories {
  admins: CometChat.User[];
  friendMembers: CometChat.User[];
  royalMembers: CometChat.User[];
  vipMembers: CometChat.User[];
  premiumMembers: CometChat.User[];
  regularMembers: CometChat.User[];
}

const GroupListComponent = ({
  members,
  setMembers,
  loggedInUser,
  friends,
  blockedUsers,
  handleBlockUser,
  hasSentFriendRequest,
  hasReceivedFriendRequest,
  handleAddOrRemoveFriend,
  handleMuteUser,
  handleKickUser,
  handleLogoutKickUser,
  handleBanUser,
  currentChat,
  setCurrentChat,
  startPrivateChat,
  setUserToReport,
  setUserToWarn,
  setAlert,
  functions,
  userSettings,
  refreshConversations,
  handleInitiateCall,
  handleTerminateCall,
  ongoingCall,
  outgoingCall,
}: {
  members: CometChat.GroupMember[];
  setMembers: (members: CometChat.GroupMember[]) => void;
  loggedInUser: CometChat.User | null;
  friends: CometChat.User[];
  blockedUsers: CometChat.User[];
  handleBlockUser: (userId: string) => void;
  hasSentFriendRequest: (user: CometChat.User) => boolean;
  hasReceivedFriendRequest: (user: CometChat.User) => boolean;
  handleAddOrRemoveFriend: (friend: CometChat.User) => void;
  handleMuteUser: (user: CometChat.User) => void;
  handleKickUser: (user: CometChat.User, group: CometChat.Group) => void;
  handleLogoutKickUser: (user: CometChat.User) => void;
  handleBanUser: (user: CometChat.User) => void;
  currentChat: ActiveChannelInterface;
  setCurrentChat: (chat: any) => void;
  startPrivateChat: (user: CometChat.User) => void;
  setUserToReport: (userToReport: { user: CometChat.User, guid?: string } | null) => void;
  setUserToWarn: (user: CometChat.User) => void;
  setAlert: React.Dispatch<React.SetStateAction<{ message: string; type: string; } | null>>;
  functions: Functions;
  userSettings: UserSettingsInterface;
  refreshConversations: () => void;
  handleInitiateCall: (user: CometChat.User) => void;
  handleTerminateCall: () => void;
  ongoingCall: Call | null;
  outgoingCall: { call: Call, receiver: CometChat.User } | null;
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [categorizedMembers, setCategorizedMembers] = useState<MemberCategories>({
    admins: [],
    friendMembers: [],
    royalMembers: [],
    vipMembers: [],
    premiumMembers: [],
    regularMembers: []
  });

  const fileInputRef = useRef<HTMLInputElement>(null);
  const generalFontSize = userSettings.generalFontSize;

  // Memoized helper functions to prevent unnecessary re-renders
  const checkIfFriend = useCallback(
    (user: CometChat.User) => friends.some(friend => friend.getUid() === user.getUid()),
    [friends]
  );

  const isPublicProfile = useCallback((user: CometChat.User) => {
    const profile = getProfileFromMetadata(user);
    return !profile.settings?.profileVisibility || profile.settings.profileVisibility === "public" ||
      (profile.settings.profileVisibility === "friends" && friends.some(friend => friend.getUid() === user.getUid()));
  }, []);

  // Memoize categorization to prevent unnecessary re-computations
  const categorizeMembersMemorized = useMemo(() => {
    const sortedUniqueMembers = members
      .sort((a, b) => a.getName().localeCompare(b.getName()))
      .filter((member, index, self) =>
        index === self.findIndex(m => m.getUid() === member.getUid())
      );

    const newCategorizedMembers: MemberCategories = {
      admins: [],
      friendMembers: [],
      royalMembers: [],
      vipMembers: [],
      premiumMembers: [],
      regularMembers: []
    };

    sortedUniqueMembers.forEach(member => {
      if (checkIfUserHasPermissions(member)) {
        newCategorizedMembers.admins.push(member);
        return;
      }

      if (checkIfFriend(member)) {
        newCategorizedMembers.friendMembers.push(member);
        return;
      }

      const role = member.getRole();
      switch (role) {
        case "royal": newCategorizedMembers.royalMembers.push(member); break;
        case "vip": newCategorizedMembers.vipMembers.push(member); break;
        case "premium": newCategorizedMembers.premiumMembers.push(member); break;
        default: newCategorizedMembers.regularMembers.push(member);
      }
    });

    return newCategorizedMembers;
  }, [members, friends, checkIfFriend]);

  // Update categorized members whenever dependencies change
  useEffect(() => {
    setCategorizedMembers(categorizeMembersMemorized);
  }, [categorizeMembersMemorized]);

  // Consistent handling of group member list rendering
  const renderGroupMemberList = useCallback((
    memberList: CometChat.User[],
    header?: string,
    renderSettings = false
  ) => {
    if (memberList.length === 0) return null;

    const groupMemberListItemProps = {
      loggedInUser,
      currentChat,
      startPrivateChat,
      showUserProfile: (user: CometChat.User) =>
        setCurrentChat((prev: any) => ({ ...prev, userProfile: user })),
      setUserToReport,
      setUserToWarn,
      handleAddOrRemoveFriend,
      handleBlockUser,
      handleMuteUser,
      handleKickUser,
      handleLogoutKickUser,
      handleBanUser,
      loggedInUserIsAdmin: checkIfUserIsAdmin(loggedInUser!),
      loggedInUserIsModerator: checkIfUserIsModerator(loggedInUser!),
      generalFontSize,
      handleInitiateCall,
      handleTerminateCall,
      ongoingCall,
      outgoingCall,
    };

    const renderSettingsMenuItem = () => (
      currentChat.isOwner && (
        <ListItem
          primaryText="Instellingen"
          avatar={
            <IconMenu icon={<IconSettings color={userSettings.themeColor} />}>
              <MenuItem
                text="Voeg leden toe"
                icon={<IconPeople />}
                onClick={() => setModalIsOpen(true)}
              />
              <MenuItem
                text="Verander groepsafbeelding"
                icon={<IconSticker />}
                onClick={handleImageClick}
              />
            </IconMenu>
          }
        />
      )
    );

    return (
      <List
        header={(!currentChat.isOwner || header === 'Admins') && header}
        headerStyle={getFontSizeStyle(generalFontSize)}
      >
        {renderSettings && renderSettingsMenuItem()}
        {memberList.map((member, index) => (
          <GroupMemberListItem
            key={index}
            {...groupMemberListItemProps}
            member={member}
            isAdmin={checkIfUserIsAdmin(member)}
            isModerator={checkIfUserIsModerator(member)}
            isFriend={checkIfFriend(member)}
            isBlocked={checkIfUserIsBlocked(blockedUsers, member.getUid())}
            isPublicProfile={isPublicProfile(member)}
            hasSentFriendRequest={hasSentFriendRequest(member)}
            hasReceivedFriendRequest={hasReceivedFriendRequest(member)}
            friends={friends}
          />
        ))}
      </List>
    );
  }, [
    loggedInUser,
    currentChat,
    startPrivateChat,
    setCurrentChat,
    setUserToReport,
    setUserToWarn,
    handleAddOrRemoveFriend,
    handleBlockUser,
    handleMuteUser,
    handleKickUser,
    handleLogoutKickUser,
    handleBanUser,
    blockedUsers,
    friends,
    checkIfFriend,
    isPublicProfile,
    hasSentFriendRequest,
    hasReceivedFriendRequest,
    userSettings,
    handleInitiateCall,
    handleTerminateCall,
    ongoingCall,
    outgoingCall
  ]);

  // Handlers for file input
  const handleFileChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    handleChangeGroupIcon(event);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  }, []);

  const handleImageClick = useCallback(() => {
    if (!fileInputRef.current) return;
    fileInputRef.current.click();
  }, []);

  // Implement handleChangeGroupIcon here - copy from original implementation
  const handleChangeGroupIcon = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (event.target.files == null ||
        event.target.files.length === 0 ||
        currentChat.conversation === undefined
      ) {
        throw new Error('Invalid file or conversation');
      }

      const file = event.target.files[0];

      const mediaMessage = new CometChat.MediaMessage(
        "admin_user",
        file,
        CometChat.MESSAGE_TYPE.IMAGE,
        CometChat.RECEIVER_TYPE.USER
      );

      const message = await CometChat.sendMediaMessage(mediaMessage);

      if (!(message instanceof CometChat.MediaMessage)) {
        throw new Error('Invalid message received');
      }

      const currentGroup = currentChat.conversation as any;

      const updatedGroup = new CometChat.Group(currentGroup.guid,
        currentGroup.name,
        currentGroup.type,
        "",
        message.getURL()
      );

      const updatedGroupResult = await CometChat.updateGroup(updatedGroup);

      setCurrentChat({ ...currentChat, conversation: updatedGroupResult });
      refreshConversations();
      setAlert({ message: 'Groepsafbeelding gewijzigd', type: 'success' });

    } catch (error) {
      console.error('Error updating group:', error);
      setAlert({ message: 'Er is iets mis gegaan', type: 'error' });
    }
  };

  // Return null if user is not premium and group is
  // if (premiumChannelIds.includes(currentChat.id) && !checkUserHasSubscription(loggedInUser?.getRole() ?? "")) {
  //   return null;
  // }

  return (
    <>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
        accept="image/*"
      />

      <AddUserToGroupModal
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        friends={friends}
        members={members}
        setMembers={setMembers}
        currentChat={currentChat}
        blockedUsers={blockedUsers}
        loggedInUser={loggedInUser}
        setAlert={setAlert}
        functions={functions}
      />

      {/* Render lists in order: Admins, Friends, Royal, VIP, Premium, Regular */}
      {renderGroupMemberList(categorizedMembers.admins, 'Admins')}
      {renderGroupMemberList(categorizedMembers.friendMembers, 'Vrienden')}
      {renderGroupMemberList(categorizedMembers.royalMembers, 'Royal 👑')}
      {renderGroupMemberList(categorizedMembers.vipMembers, 'VIP 🌟')}
      {renderGroupMemberList(categorizedMembers.premiumMembers, 'Premium ⭐')}
      {renderGroupMemberList(categorizedMembers.regularMembers, 'Gebruikers', true)}
    </>
  );
};

export default GroupListComponent;