import Select from "anchor-ui/select";
import MenuItem from "anchor-ui/menu-item";
import Switch from "anchor-ui/switch";
import { UserSettingsInterface } from "../../interfaces/userSettingsInterface";
import { checkUserIsRoyal, checkUserIsVIPOrRoyal } from "../../utils/checkSubscription";

const VisualsPage = ({
  loggedInUser,
  userSettings,
  setUserSettings,
  setNeedSubscriptionWarning,
}: {
  loggedInUser: CometChat.User;
  userSettings: UserSettingsInterface;
  setUserSettings: (userSettings: UserSettingsInterface) => void;
  setNeedSubscriptionWarning: React.Dispatch<React.SetStateAction<React.ReactNode | null>>;
}) => {

  const isVIPOrRoyal = checkUserIsVIPOrRoyal(loggedInUser?.getRole() ?? "");
  const isRoyal = checkUserIsRoyal(loggedInUser?.getRole() ?? "");

  const handleMessageFontSizeChange = (event: object, value: string) => {
    setUserSettings({ ...userSettings, messageFontSize: value });
  };

  const handleGeneralFontSizeChange = (event: object, value: string) => {
    setUserSettings({ ...userSettings, generalFontSize: value });
  };

  const handleMediaDisabledToggle = () => {
    setUserSettings({ ...userSettings, mediaDisabled: !userSettings.mediaDisabled });
  };

  const handleMyMsgAlignmentChange = (event: object, value: string) => {
    setUserSettings({ ...userSettings, myMsgAlignment: value });
  };

  const handleOtherMsgAlignmentChange = (event: object, value: string) => {
    setUserSettings({ ...userSettings, otherMsgAlignment: value });
  };

  const handleChangeVIPFont = (event: object, value: string) => {
    if (!isVIPOrRoyal) {
      setNeedSubscriptionWarning(
        <b>Upgrade naar VIP of Royal om deze instelling te gebruiken.</b>
      );
      return;
    }
    setUserSettings({ ...userSettings, font: value });
  };

  const handleStarDisabledToggle = () => {
    if (!isVIPOrRoyal) {
      setNeedSubscriptionWarning(
        <b>Upgrade naar VIP of Royal om deze instelling te gebruiken.</b>
      );
      return;
    }
    setUserSettings({ ...userSettings, starDisabled: !userSettings.starDisabled });
  };

  const handleChangeMsgBg = (event: object, value: string) => {
    if (!isRoyal) {
      setNeedSubscriptionWarning(
        <b>Upgrade naar VIP of Royal om deze instelling te gebruiken.</b>
      );
      return;
    }
    setUserSettings({ ...userSettings, msgBg: value });
  };

  const handleChangeMsgFont = (event: object, value: string) => {
    if (!isRoyal) {
      return;
    }
    setUserSettings({ ...userSettings, msgFont: value });
  };

  return (
    <div>
      <h2>Uiterlijk</h2>

      <section className="account-input">

        <section className="setting-select">
          <Select
            label="Berichttekstgrootte"
            value={userSettings.messageFontSize}
            onChange={handleMessageFontSizeChange}
          >
            <MenuItem text="Normaal" value="font-normal" />
            <MenuItem text="Medium" value="font-medium" />
            <MenuItem text="Groot" value="font-large" />
          </Select>
        </section>

        <section className="setting-select">
          <Select
            label="Algemene tekstgrootte"
            value={userSettings.generalFontSize}
            onChange={handleGeneralFontSizeChange}
          >
            <MenuItem text="Normaal" value="font-normal" />
            <MenuItem text="Medium" value="font-medium" />
            <MenuItem text="Groot" value="font-large" />
          </Select>
        </section>

        <section className="setting-select">
          <Switch
            label='Verberg afbeeldingen, .gif bestanden & youtube embeds in chat'
            active={userSettings.mediaDisabled}
            toggleSwitch={handleMediaDisabledToggle}
          />
        </section>

        <section className="setting-select">
          <Select
            label="Mijn berichten"
            value={userSettings.myMsgAlignment}
            onChange={handleMyMsgAlignmentChange}
          >
            <MenuItem text="Rechts uitlijnen" value="right" />
            <MenuItem text="Links uitlijnen" value="left" />
          </Select>
        </section>

        <section className="setting-select">
          <Select
            label="Andermans berichten"
            value={userSettings.otherMsgAlignment}
            onChange={handleOtherMsgAlignmentChange}
          >
            <MenuItem text="Rechts uitlijnen" value="right" />
            <MenuItem text="Links uitlijnen" value="left" />
          </Select>
        </section>

        <br /><br />

        <h2>VIP & Royal</h2>
        <section className="setting-select">
          <Select
            label="Website font"
            value={userSettings.font}
            onChange={handleChangeVIPFont}
            disabled={!isVIPOrRoyal}
          >
            <MenuItem text="Standaard" value="default" />
            <MenuItem text="Roboto" value="roboto" className={`font-roboto`} />
            <MenuItem text="Rochester" value="rochester" className={`font-rochester`} />
            <MenuItem text="Quicksand" value="quicksand" className={`font-quicksand`} />
            <MenuItem text="Caveat" value="caveat" className={`font-caveat`} />
          </Select>
          {!isVIPOrRoyal && <p className="upgrade-message">Upgrade naar VIP of Royal om deze instelling te gebruiken.</p>}
        </section>

        <section className="setting-select">
          <Switch
            label="Verberg gele ster/kroon in berichten"
            active={userSettings.starDisabled}
            toggleSwitch={handleStarDisabledToggle}
          />
        </section>

        <br /><br />
        <h2>Royal</h2>
        <section className="setting-select">
          <Select
            label="Berichtachtergrondkleur"
            value={userSettings.msgBg}
            onChange={handleChangeMsgBg}
            disabled={!isRoyal}
          >
            <MenuItem text="Standaard" value="" />
            <MenuItem style={{ color: "white", backgroundColor: "#d32f2f" }} text="Rood" value="#d32f2f" />
            <MenuItem style={{ color: "white", backgroundColor: "#f2922c" }} text="Oranje" value="#f2922c" />
            <MenuItem style={{ color: "black", backgroundColor: "#fbc02d" }} text="Geel" value="#fbc02d" />
            <MenuItem style={{ color: "white", backgroundColor: "#22ac55" }} text="Groen" value="#22ac55" />
            <MenuItem style={{ color: "white", backgroundColor: "#177FBF" }} text="Blauw" value="#177FBF" />
            <MenuItem style={{ color: "white", backgroundColor: "#5d3c85" }} text="Paars" value="#5d3c85" />
            <MenuItem style={{ color: "white", backgroundColor: "#212121" }} text="Zwart" value="#212121" />
          </Select>
          {!isRoyal && <p className="upgrade-message">Upgrade naar Royal om deze instelling te gebruiken.</p>}
        </section>

        <section className="setting-select">
          <Select
            label="Berichttekstfont"
            value={userSettings.msgFont}
            onChange={handleChangeMsgFont}
            disabled={!isRoyal}
          >
            <MenuItem text="Standaard" value="default" />
            <MenuItem text="Roboto" value="roboto" className={`font-roboto`} />
            <MenuItem text="Rochester" value="rochester" className={`font-rochester`} />
            <MenuItem text="Quicksand" value="quicksand" className={`font-quicksand`} />
            <MenuItem text="Caveat" value="caveat" className={`font-caveat`} />
          </Select>
          {!isRoyal && <p className="upgrade-message">Upgrade naar Royal om deze instelling te gebruiken.</p>}
        </section>
      </section>
    </div>
  );
}

export default VisualsPage;