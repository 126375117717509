export const ChatBotMessages = [
  {
    type: "subscriptionAd",
    subscriptionType: "premium",
    message: "⭐ Met een Premium abonnement krijg je de mogelijkheid om max. 100 andere gebruikers te blokkeren en ben je altijd bovenaan de online lijst!",
  },
  {
    type: "subscriptionAd",
    subscriptionType: "premium",
    message: "⭐ Wist je dat je met Premium ook tot 2 privé groepen kan aanmaken? Perfect voor privégesprekken!",
  },
  {
    type: "subscriptionAd",
    subscriptionType: "premium",
    message: "⭐ Als Premium gebruiker krijg je korting wanneer je naar VIP of Royal upgradet! Ontgrendel exclusieve functies en krijg meer mogelijkheden voor jouw chatervaring!",
  },
  {
    type: "subscriptionAd",
    subscriptionType: "premium",
    message: "⭐ Als Premium gebruiker of hoger kan je audio- en videogesprekken voeren met iedereen, ook met niet-betalende gebruikers. Maak verbinding met iedereen!",
  },
  {
    type: "subscriptionAd",
    subscriptionType: "vip",
    message: "🌟 VIP of hoger geeft je toegang tot exclusieve lettertypes voor je profiel, meer ruimte voor galerijfoto's en de mogelijkheid om je eigen profielbanner in te stellen!",
  },
  {
    type: "subscriptionAd",
    subscriptionType: "vip",
    message: "🌟 Met VIP kun je tot wel 5 privé groepen aanmaken. Perfect voor privé gesprekken met verschillende groepen!",
  },
  {
    type: "subscriptionAd",
    subscriptionType: "vip",
    message: "🌟 VIP biedt alle voordelen van Premium, plus de extra ruimte voor galerijfoto's en de mogelijkheid om unieke profiel aanpassingen te maken!",
  },
  {
    type: "subscriptionAd",
    subscriptionType: "royal",
    message: "👑 Met Royal krijg je een prestigieuze badge én de mogelijkheid om je berichtenstijl aan te passen zodat je altijd opvalt!",
  },
  {
    type: "subscriptionAd",
    subscriptionType: "royal",
    message: "👑 Wist je dat Royal gebruikers onbeperkt privé groepen kunnen creëren en genieten van een advertentievrije ervaring? Geen afleidingen meer!",
  },
  {
    type: "subscriptionAd",
    subscriptionType: "royal",
    message: "👑 Als Royal lid krijg je alle voordelen van VIP en Premium, plus de mogelijkheid om een premium ervaring te beleven zonder advertenties!",
  },
  {
    type: "subscriptionAd",
    subscriptionType: "royal",
    message: "👑 Upgrade naar Royal en geef jezelf de ultieme chatervaring: onbeperkt privé groepen en een volledig op maat gemaakte berichtenstijl!",
  },

  // Channel promos
  {
    type: "channelAd",
    message: "⚽ Ben jij een echte sportfan? In het sportkanaal praat je mee over voetbal, fitness, Formule 1 en nog veel meer. Deel je passie met andere enthousiaste liefhebbers! Of je nu een fan bent van voetbal, basketbal of andere sporten, dit is de plek om je sportieve hart op te halen.",
    channelId: "group__1712058916009",
  },
  {
    type: "channelAd",
    message: "🎭 Zin in wat rolspel en avontuur? In het roleplay kanaal kun je je creativiteit de vrije loop laten, verschillende personages aannemen en meeslepende verhalen creëren met andere spelers. Of je nu van fantasy, science fiction of drama houdt, er is altijd een nieuw avontuur te beleven!",
    channelId: "roleplay",
  },
  {
    type: "channelAd",
    message: "😈 Durf jij het aan? Speel mee in het Truth or Dare kanaal waar de spanning en lol nooit ophouden! Daag je vrienden uit met gewaagde vragen en opdrachten. Wat is jouw stoutste uitdaging?",
    channelId: "truthordare",
  },
  {
    type: "channelAd",
    message: "❓ Heb je vragen of zoek je hulp? Het Admin Hulp kanaal is de plek waar je alles kunt vragen en waar je deskundige hulp krijgt van de moderators en andere ervaren gebruikers. Of het nu gaat om technische problemen of gewoon chatregels, hier krijg je snel antwoord!",
    channelId: "admin_chat",
  },
  {
    type: "channelAd",
    message: "🔥 Ben je op zoek naar spanning en sensatie? De Parenclub is een gezellig en dynamisch kanaal voor koppels en singles die willen chatten, flirten en gewoon een goede tijd willen hebben. Kom en ontdek nieuwe mensen in een veilige en leuke omgeving!",
    channelId: "parenclub",
  },
  {
    type: "channelAd",
    message: "💬 Zoek je een grotere chatervaring? Chat XL is het perfecte kanaal voor jou! Het is altijd druk, met veel mensen om mee te praten, nieuwe vriendschappen te maken en gewoon gezellig te kletsen. De ultieme plek voor serieuze of informele gesprekken!",
    channelId: "chatxl",
  },
  {
    type: "channelAd",
    message: "👠 Hou je van gezelligheid, fashion en beauty? Het Babes kanaal is waar je alles kunt delen over de laatste trends, beauty tips en gewoon lekker kunt kletsen met gelijkgestemde mensen. Sluit je aan en ontdek wat er allemaal te beleven valt!",
    channelId: "babes",
  },
  {
    type: "channelAd",
    message: "🧖‍♀️ Heb je zin om te ontspannen? In het Sauna kanaal kun je een moment voor jezelf nemen, nieuwe mensen ontmoeten en gesprekken voeren over alles wat ontspanning betreft. Perfect voor een chill en zen ervaring!",
    channelId: "sauna",
  },
  {
    type: "channelAd",
    message: "🎉 Ben je op zoek naar een relatie of wil je gewoon lekker flirten? In het Vrijgezellen kanaal ontmoet je andere singles die ook op zoek zijn naar iets speciaals. Kom binnen, maak een praatje en wie weet vind jij wel een match!",
    channelId: "vrijgezellen",
  },
  {
    type: "channelAd",
    message: "🌈 De Bi-box is een veilige en open ruimte voor de bi-gemeenschap en iedereen die nieuwsgierig is. Kom in contact met anderen die jouw seksuele oriëntatie delen en creëer een ondersteunende en inclusieve gemeenschap.",
    channelId: "bibox",
  },
  {
    type: "channelAd",
    message: "🏠 De Huiskamer is de ideale plek voor casual gesprekken en gezelligheid. Of je nu een goed gesprek wilt voeren of gewoon wat wilt ontspannen, hier is altijd iemand die mee wil praten!",
    channelId: "huiskamer",
  },
  {
    type: "channelAd",
    message: "📍 Wil je altijd op de hoogte blijven van het laatste nieuws? Chatpoint is de perfecte plek om bij te praten, van alles te leren en mee te doen aan live gesprekken met andere chatters.",
    channelId: "chatpoint",
  },
];
